import getClient from '@/plugins/vue-apollo/client'
import {
  Block,
  Button,
  Chart,
  Collection,
  Filter,
  Form,
  Indicator,
  Table,
  Task
} from '@/models'
import * as monaco from 'monaco-editor'
import gql from 'graphql-tag'
import FuzzySearch from 'fuzzy-search'
import { editorEnv } from '.'

async function getComponentsOfType<T>(componentType: string) {
  const client = getClient()
  const { data: result } = await client.query({
    query: gql`query getComponentsOfType_${componentType} ($environmentId: ID) {
      ${componentType} (environmentId: $environmentId) {
        items {
          _id
          name
        }
      }
    }`,
    variables: { environmentId: editorEnv.environmentId }
  })
  return ((result && result[componentType] && result[componentType].items) ||
    []) as T[]
}

// Codelens for component editor
const componentEditorCodelensProvider: monaco.languages.CodeLensProvider = {
  async provideCodeLenses(model, token) {
    const lenses: monaco.languages.CodeLens[] = []

    async function addCodelensesFor<T extends { _id: string; name: string }>(
      kind: string,
      pattern: string,
      displayName: string,
      matchFn = (name: string, c: T): boolean =>
        c.name === name || c._id === name,
      matchIndex = 3,
      errorText = ''
    ) {
      const refs = model.findMatches(pattern, false, true, true, null, true)
      if (refs.length > 0) {
        const comps = await getComponentsOfType<T>(kind)
        for (const ref of refs) {
          if (!ref.matches) continue

          const name = ref.matches[matchIndex]
          const comp = comps.find((c) => matchFn(name, c))

          lenses.push({
            range: ref.range,
            id: `${kind}_${comp?._id || name}`,
            command: {
              id: comp ? 'CODELESS_EDIT_COMPONENT' : 'CODELESS_ALERT',
              title: comp
                ? `🛠️ ${displayName}: ${comp.name}`
                : errorText
                ? errorText
                : `⚠️ ${displayName.toUpperCase()} "${name}" NO EXISTE`,
              arguments: comp
                ? [kind, comp._id]
                : [
                    'No se encuentra el componente referenciado. Revisa que esté bien escrito.'
                  ]
            }
          })
        }
      }
    }

    await addCodelensesFor<Collection>(
      'collections',
      '(aggregateCollection|createItem|deleteItems?|getCollection|getItem|updateItems?|upsertItem|countItems|createUser)\\([\\s\\r\\n]*([\'`"])([\\w\\d]+)\\2',
      'Colección',
      (name, c) => c._id === `${editorEnv.environmentId}_${name}`
    )

    await addCodelensesFor<Collection>(
      'collections',
      '((["\'`])col_\\1(.*?)*["\'`])',
      'Colección',
      (name, c) => {
        const col = name
          .replace(/["'`]+/g, '')
          .split('_')
          .slice(-1)[0]
        return c._id === `${editorEnv.environmentId}_${col}`
      },
      1,
      '⚠️ NO SE PUDO ENCONTRAR LA COLECCIÓN'
    )

    await addCodelensesFor<Filter>(
      'filters',
      '(getFilter|getFilterItem)\\([\\s\\r\\n]*([\'`"])(.+?)\\2',
      'Filtro'
    )

    await addCodelensesFor<Indicator>(
      'indicators',
      '(getIndicator)\\([\\s\\r\\n]*([\'`"])(.+?)\\2',
      'Indicador'
    )

    await addCodelensesFor<Table>(
      'tables',
      '(getTable)\\([\\s\\r\\n]*([\'`"])(.+?)\\2',
      'Tabla'
    )

    await addCodelensesFor<Chart>(
      'charts',
      '(getChart)\\([\\s\\r\\n]*([\'`"])(.+?)\\2',
      'Gráfico'
    )

    await addCodelensesFor<Task>(
      'tasks',
      '(runTask|stopTask|taskStatus)\\([\\s\\r\\n]*([\'`"])([\\w\\d]+)\\2',
      'Tarea'
    )

    await addCodelensesFor<Button>(
      'buttons',
      'href=(["\'])#btn-(.+?)\\1',
      'Botón',
      (name, b) => b._id === name,
      2
    )

    await addCodelensesFor<Block>(
      'blocks',
      'href=(["\'])#md-block-(.+?)\\1',
      'Bloque de contenido',
      (name, b) => b._id === name,
      2
    )

    await addCodelensesFor<Form>(
      'forms',
      'href=(["\'])#md-form-(.+?)\\1',
      'Formulario',
      (name, b) => b._id === name,
      2
    )

    return {
      lenses,
      dispose: () => {}
    }
  }
}

// Built-in function completions
const functionCompletionItemProvider: monaco.languages.CompletionItemProvider =
  {
    async provideCompletionItems(model, position) {
      let textUntilPosition = model.getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column
      })

      // Functions
      let functionNames = [
        'aggregateCollection',
        // 'createItem',
        // 'deleteItem',
        // 'deleteItems',
        'createUser',
        'getChart',
        'getCollection',
        'getFilter',
        'getFilterItem',
        'getIndicator',
        'getItem',
        'getTable',
        // 'updateItem',
        // 'udateItems',
        'formatDate',
        'log',
        'format',
        'num',
        'numeroALetras',
        'moment',
        'numeral'
      ]

      let currentWord = model.getWordAtPosition(position)

      if (currentWord && textUntilPosition.match(/[^\s]\S+$/)) {
        return {
          suggestions: functionNames.map((f) => ({
            kind: monaco.languages.CompletionItemKind.Function,
            filterText: f,
            label: f,
            insertText: `${f}(`,
            range: {
              startColumn: currentWord!.startColumn,
              startLineNumber: position.lineNumber,
              endColumn: currentWord!.endColumn,
              endLineNumber: position.lineNumber
            }
          }))
        }
      }

      return { suggestions: [] }
    }
  }

// Component name completions
const componentNameCompletionItemProvider: monaco.languages.CompletionItemProvider =
  {
    async provideCompletionItems(model, position) {
      let textUntilPosition = model.getValueInRange({
        startLineNumber: 1,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column
      })

      function findCurrent(pattern: string) {
        return model.findPreviousMatch(
          pattern,
          position,
          true,
          true,
          null,
          true
        )
      }

      // Collections
      const colRef = textUntilPosition.match(
        /(aggregateCollection|createItem|deleteItems?|getCollection|getItem|updateItems?|upsertItem|countItems|createUser?)\((['`"])?([\w\d]+)?$/
      )
      if (colRef) {
        const collections = await getComponentsOfType<Collection>('collections')
        const searcher = new FuzzySearch(collections || [], ['name', '_id'])
        const matches = searcher.search(colRef[3])
        return {
          suggestions: matches.map((c) => ({
            kind: monaco.languages.CompletionItemKind.Constant,
            label: c.name,
            insertText: `'${c._id.split('_')[1]}', {}`,
            range: {
              startColumn:
                position.column -
                (colRef[2] || '').length -
                (colRef[3] || '').length,
              startLineNumber: position.lineNumber,
              endColumn: position.column,
              endLineNumber: position.lineNumber
            }
          }))
        }
      }

      // Other Functions
      const comRef = textUntilPosition.match(
        /(getFilter|getFilterItem|getIndicator|getTable|getChart|runTask|stopTask|taskStatus)\((['`"])?(.+?)?$/
      )
      if (comRef) {
        let suffix = ', {}'
        let component = ''
        switch (comRef[1]) {
          case 'getFilter':
          case 'getFilterItem':
            component = 'filters'
            break
          case 'getIndicator':
            component = 'indicators'
            break
          case 'getTable':
            component = 'tables'
            suffix = ''
            break
          case 'getChart':
            component = 'charts'
            suffix = ''
            break
          case 'runTask':
          case 'stopTask':
          case 'taskStatus':
            component = 'tasks'
            break
          default:
            return { suggestions: [] }
        }

        const comps = await getComponentsOfType<{ _id: string; name: string }>(
          component
        )

        const searcher = new FuzzySearch(comps || [], ['name', '_id'])
        const matches = searcher.search(comRef[3])
        return {
          suggestions: matches.map((c) => ({
            kind: monaco.languages.CompletionItemKind.Constant,
            label: c.name,
            insertText: `'${c.name}'${suffix}`,
            range: {
              startColumn:
                position.column -
                (comRef[2] || '').length -
                (comRef[3] || '').length,
              startLineNumber: position.lineNumber,
              endColumn: position.column,
              endLineNumber: position.lineNumber
            }
          }))
        }
      }

      // Definición de los tipos de componentes disponibles
      const componentTypes = [
        'tasks',
        'vueBlocks',
        'tables',
        'forms',
        'blocks',
        'buttons',
        'charts',
        'collections',
        'filters',
        'hooks',
        'indicators',
        'roles',
        'apiEndpoints',
        'endpoints',
        'views',
        'links'
      ]
      // Extracción de información desde el editor hasta la posición actual
      const typeRef = textUntilPosition.match(
        /(getComponent?|getComponents?|updateComponent?|updateComponents?)\((['`"])?(.+?)?$/
      )

      // Si se encuentra una coincidencia en el texto hasta la posición actual
      if (typeRef) {
        // Filtrar los tipos de componentes que coinciden parcialmente con la búsqueda
        const matches = componentTypes.filter((type) =>
          type.includes(typeRef[3])
        )
        // Retornar sugerencias de autocompletado basadas en los tipos coincidentes
        return {
          suggestions: matches.map((type) => ({
            // Tipo de ítem para mostrar en el autocompletado
            kind: monaco.languages.CompletionItemKind.Constant,
            // Etiqueta del ítem que se mostrará en la lista de autocompletado
            label: type,
            // Texto a insertar cuando se seleccione este ítem de autocompletado
            insertText: `'${type}', {}`,
            // Definir el rango en el que se aplicará este autocompletado
            range: {
              startColumn:
                position.column -
                (typeRef[2] || '').length -
                (typeRef[3] || '').length,
              startLineNumber: position.lineNumber,
              endColumn: position.column,
              endLineNumber: position.lineNumber
            }
          }))
        }
      }
    }
  }

// Register codelenses for EJS
monaco.languages.registerCodeLensProvider(
  'ejs',
  componentEditorCodelensProvider
)
// Register codelenses for JavaScript
monaco.languages.registerCodeLensProvider(
  'javascript',
  componentEditorCodelensProvider
)
// Register completions for EJS
monaco.languages.registerCompletionItemProvider(
  'ejs',
  functionCompletionItemProvider
)
monaco.languages.registerCompletionItemProvider(
  'ejs',
  componentNameCompletionItemProvider
)
// Register completions for JavaScript
// monaco.languages.registerCompletionItemProvider('javascript', functionCompletionItemProvider)
monaco.languages.registerCompletionItemProvider(
  'javascript',
  componentNameCompletionItemProvider
)
